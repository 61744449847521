.videoBox {
  padding: 0 16px;
  padding-top: 25%;
  box-sizing: border-box;
  min-width: 375px;
  max-width: 750px;
  margin: 0 auto;
  .tooltip {
    margin-bottom: 10px;
  }
  .videoHeader {
    display: flex;
    &:extend(.videoBox .tooltip);
  }
}

.videoPlay {
  margin-top: 20px;
  width: 100%;
  iframe {
    width: 100%;
    min-height: 300px;
    max-height: 600px;
    background: #000;
  }
  .emptyBox {
    width: 100%;
    min-height: 300px;
    max-height: 600px;
    background: #000;
    color: #fff;
    display: grid;
    place-items: center;
  }
  @media screen and (max-width: 1180px) {
    iframe {
      min-height: 300px;
      max-height: 600px;
    }
    .emptyBox {
      min-height: 300px;
      max-height: 600px;
    }
  }
}

@media screen and (min-width: 1180px) {
  .videoBox {
    padding-top: 15% !important;
  }
}

